import banner from "./ReportStatus.png";
import { useTranslation } from "react-i18next";
import {
  BsCircleFill,
  BsClock,
  BsFillExclamationCircleFill,
} from "react-icons/bs";

import { useContext, useEffect, useRef, useState } from "react";
import FormDataContext from "../../FormDataContext";
import VoiceRecorder from "../../components/VoiceRecorder";
import { CgAttachment } from "react-icons/cg";
import { Link } from "react-router-dom";
import axios from "axios";

const ReportStatus = () => {
  const [message, setMessage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const inputRef = useRef();

  const {
    formData,
    updateFormData,
    userReportDetails: caseDetails,
    getCaseByCode,
    uniqueNumber,
  } = useContext(FormDataContext);
  const { t } = useTranslation();

  const handleUpload = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };
  const reportDetails = JSON.parse(localStorage.getItem("userReportDetails"));

  const handleTextareaChange = (event) => {
    setMessage(event.target.value);
    updateFormData({ message: event.target.value });
  };
  const handleDisplayFileDetails = () => {
    if (inputRef?.current?.files) {
      const file = inputRef.current.files[0];
      setUploadedFileName(file?.name);
      updateFormData({ file });
    }
  };
  const textareaStyle = {
    resize: "none",
    backgroundColor: "#D9D9D9",
  };
  const updateCaseComment = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "updateCaseComment");
    formDataToSend.append("case_code", uniqueNumber);
    formDataToSend.append("file", uploadedFileName);
    formDataToSend.append("message", message);
    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getCaseByCode(uniqueNumber);
  }, [caseDetails.status]);
  return (
    <section className="container">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center  order-1 order-md-0 ">
          <h2 className="fs-1 fw-bold secondary-color">{t("repStatus")}</h2>
          <div
            className={`warning-div py-4 rounded-4 m-2 m-md-5 mt-md-2 p-2 mb-md-2 position-relative
              ${
                caseDetails?.status === "new"
                  ? "border-dark"
                  : caseDetails?.status === "in-progress"
                  ? "border-warning"
                  : caseDetails?.status === "closed"
                  ? "border-success"
                  : caseDetails?.status === "evidence_required"
                  ? "border border-danger"
                  : " "
              }
              `}
          >
            <div className=" d-flex align-items-center justify-content-between  m-3 ms-md-5 gap-3 mb-2">
              <div>
                <span>
                  <BsClock size={35} />
                </span>
                <span className="primary-color ms-3 pe-3">
                  {reportDetails?.creattion_time || caseDetails.creation_date}
                </span>
              </div>
              <div className="">
                <Link
                  to="/case-details"
                  className="text-decoration-none secondary-color"
                >
                  {t("details")}
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center  ms-3 ms-md-5 gap-3 mb-2">
              <span>
                <svg id="svg" height="35" width="35">
                  <circle
                    cx="50%"
                    cy="50%"
                    r="50%"
                    stroke=""
                    stroke-width="3"
                    fill={
                      reportDetails?.status === "new"
                        ? "gray"
                        : reportDetails?.status === "in-progress"
                        ? "yellow"
                        : reportDetails?.status === "closed"
                        ? "green"
                        : reportDetails?.status === "evidence_required"
                        ? "red"
                        : "none"
                    }
                  />
                </svg>
              </span>
              {reportDetails?.status === "new" && (
                <span className="fs-3 text-muted ">{t("noReply")}</span>
              )}
              {reportDetails?.status === "in-progress" && (
                <span className="fs-3 text-warning">{t("checkingRep")}</span>
              )}
              {reportDetails?.status === "closed" && (
                <span className="fs-3 text-success">
                  {t("finished&Replied")}
                </span>
              )}
              {reportDetails?.status === "evidence_required" && (
                <span className="fs-3 text-danger">
                  {t("evidenceRequired")}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center  ms-3 ms-md-5 gap-3 mb-2">
              {reportDetails?.status === "evidence_required" ? (
                <div className="d-flex align-items-center  gap-3 mb-2 ">
                  <span className="primary-color ">
                    <BsFillExclamationCircleFill size={35} fill="#00B0C9" />
                  </span>
                  <span className="primary-color ">
                    update : {t("evidenceRequired")}
                  </span>
                </div>
              ) : (
                " "
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-5 p-5 p-md-0  text-center">
          <img
            src={banner}
            className="img-fluid"
            alt="various phrases indicating the concept of the application"
          />
        </div>
      </div>
    </section>
  );
};

export default ReportStatus;
