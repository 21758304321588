import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsClock, BsFillExclamationCircleFill } from "react-icons/bs";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FormDataContext from "../../FormDataContext";
import { CgAttachment } from "react-icons/cg";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Skeleton/SkeletonSpinner";

const CaseDetails = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const inputRef = useRef();

  const {
    userReportDetails: caseDetails,
    uniqueNumber,
    getCaseByCode,
    updateFormData,
  } = useContext(FormDataContext);

  const [isreply, setisreply] = useState(false);

  useEffect(() => {
    if (uniqueNumber) {
      getCaseByCode(uniqueNumber);
    }
  }, [uniqueNumber]);

  const handleUpload = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleTextareaChange = (event) => {
    setMessage(event.target.value);
  };
  const handleDisplayFileDetails = () => {
    if (inputRef?.current?.files?.length > 0) {
      setUploadedFileName(inputRef.current.files[0]?.name);
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!message.trim()) return;

      const formDataToSend = new FormData();
      formDataToSend.append("data_type", "updateCaseComment");
      formDataToSend.append("case_code", uniqueNumber);
      formDataToSend.append("comment", message);

      if (inputRef.current?.files[0]) {
        formDataToSend.append("comment_file", inputRef.current.files[0]);
      }

      try {
        await axios.post(
          "https://speakup.mountainviewegypt.com/__API/index.php",
          formDataToSend
        );
        getCaseByCode(uniqueNumber);
        setMessage("");
        setUploadedFileName(null);
        setisreply(true);
        setTimeout(() => {
          setisreply(false);
        }, 3000);
      } catch (error) {
        console.error("Error submitting comment:", error.message);
      }
    },
    [message, uniqueNumber]
  );

  const viewFile = (filePath) => {
    if (!filePath) {
      console.warn("⚠️ No file available to view.");
      return;
    }
    window.open(filePath, "_blank");
  };
  const caseData = useMemo(() => {
    return {
      code: caseDetails?.code || "",
      creation_date: caseDetails?.creation_date || "N/A",
      reply: caseDetails?.reply || "No Reply",
      status: caseDetails?.status || "N/A",
    };
  }, [caseDetails]);

  return (
    <section className="container">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12 col-md-9 d-flex flex-column justify-content-center text-center order-1 order-md-0">
          <h2 className="fs-1 fw-bold secondary-color pb-5">
            {t("repStatus")}
          </h2>

          {!caseDetails || !caseDetails.code ? (
            <Loader />
          ) : (
            <>
              <div
                className={`warning-div py-4 rounded-4 m-2 m-md-5 mt-md-2 p-2 mb-md-2 position-relative
              ${
                caseDetails?.status === "new"
                  ? "border-dark"
                  : caseDetails?.status === "in-progress"
                  ? "border-warning"
                  : caseDetails?.status === "closed"
                  ? "border-success"
                  : caseDetails?.status === "evidence_required"
                  ? "border border-danger"
                  : " "
              }
              `}
              >
                <div className="d-flex align-items-center justify-content-between m-3 ms-md-5 gap-3">
                  <div>
                    <BsClock size={35} />
                    <span className="primary-color ms-3 pe-3">
                      {caseDetails.creation_date || "N/A"}
                    </span>
                  </div>
                  <div>
                    <Link
                      to="/case-details"
                      className="text-decoration-none primary-color"
                    >
                      Ticket No. {caseDetails.code}
                    </Link>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 ms-md-5 gap-3 mb-2 pe-3">
                  <svg id="svg" height="35" width="35">
                    <circle
                      cx="50%"
                      cy="50%"
                      r="50%"
                      stroke=""
                      stroke-width="3"
                      fill={
                        caseDetails?.status === "new"
                          ? "gray"
                          : caseDetails?.status === "in-progress"
                          ? "yellow"
                          : caseDetails?.status === "closed"
                          ? "green"
                          : caseDetails?.status === "evidence_required"
                          ? "red"
                          : "none"
                      }
                    />
                  </svg>

                  {caseDetails?.status === "new" && (
                    <span className="fs-3 text-muted">{t("noReply")}</span>
                  )}
                  {caseDetails?.status === "in-progress" && (
                    <span className="fs-3 text-warning">
                      {t("checkingRep")}
                    </span>
                  )}
                  {caseDetails?.status === "closed" && (
                    <span className="fs-3 text-success">
                      {t("finished&Replied")}
                    </span>
                  )}
                  {caseDetails?.status === "evidence_required" && (
                    <span className="fs-3 text-danger">
                      {t("evidenceRequired")}
                    </span>
                  )}
                </div>

                {caseDetails?.reply && (
                  <div className="d-flex align-items-center justify-content-between ms-3 ms-md-5 gap-3 mb-2">
                    <span className="primary-color pe-3">
                      <BsFillExclamationCircleFill size={35} fill="#00B0C9" />
                      &nbsp;&nbsp; Update: {caseDetails.reply}
                    </span>
                    {caseDetails.reply_file && (
                      <button
                        className="btn border-0 p-1 px-3 rounded-pill text-white"
                        style={{ backgroundColor: `var(--main-color2)` }}
                        onClick={() => viewFile(caseDetails.reply_file)}
                      >
                        <FiDownload size={20} /> View Admin File
                      </button>
                    )}
                  </div>
                )}
                {caseDetails?.final_reply && (
                  <div className="d-flex align-items-center justify-content-between ms-3 ms-md-5 gap-3 mb-2">
                    <span className="primary-color pe-3">
                      <BsFillExclamationCircleFill size={35} fill="#00B0C9" />
                      &nbsp;&nbsp; Update: {caseDetails.final_reply}
                    </span>
                    {caseDetails.final_file && (
                      <button
                        className="btn border-0 p-1 px-3 rounded-pill text-white"
                        style={{ backgroundColor: `var(--main-color2)` }}
                        onClick={() => viewFile(caseDetails.final_file)}
                      >
                        <FiDownload size={20} /> View Admin File
                      </button>
                    )}
                  </div>
                )}
              </div>
              {isreply ? (
                <div className="text-success py-4">
                  <p>Submit Successfully</p>
                </div>
              ) : (
                " "
              )}
              {caseDetails.status === "evidence_required" &&
              !caseDetails.comment ? (
                <Form
                  onSubmit={handleSubmit}
                  className="m-2 m-md-5 mt-md-2 p-2 px-0"
                >
                  <Form.Group controlId="textarea">
                    <Form.Control
                      as="textarea"
                      className="textare p-3 secondary-color container"
                      rows={1}
                      style={{ resize: "none" }}
                      placeholder="Add Comment"
                      onChange={handleTextareaChange}
                      value={message}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between align-items-center pt-3">
                    <span style={{ fontSize: "11px" }}>
                      <h6>{t("attachTypes2")}</h6>
                    </span>
                    <div>
                      <span>
                        <input
                          type="file"
                          ref={inputRef}
                          className="d-none"
                          onChange={handleDisplayFileDetails}
                        />
                        <button className="btn" onClick={handleUpload}>
                          <span>{uploadedFileName}</span>
                          <span>
                            <CgAttachment
                              cursor="pointer"
                              size={30}
                              className="chat-icon"
                            />
                          </span>
                        </button>
                      </span>

                      <button
                        className="button border-0 text-white submit-btn rounded-1 fs-5 py-2 px-4"
                        disabled={!message.trim()}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </div>
                </Form>
              ) : (
                " "
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default CaseDetails;
