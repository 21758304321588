import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    company: "",
    classification: "",
    message: "",
    file: null,
    record: null,
  });
  const [checkboxData, setCheckboxData] = useState({
    feedback_manager: false,
    feedback_Team: false,
    read_conduct: false,
    have_read: false,
  });
  const updateCheckboxData = (newData) => {
    setCheckboxData(newData);
  };

  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [uniqueNumber, setUniqueNumber] = useState(
    localStorage.getItem("caseNumber") || null
  );

  const [userReportDetails, setUserReportDetails] = useState(
    JSON.parse(localStorage.getItem("userReportDetails")) || {}
  );
  const [allCases, setAllCases] = useState([]);

  const getAllCases = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getAllCases");

    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );

      if (res?.data?.cases) {
        setAllCases(res.data.cases);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCases();
    const intervalId = setInterval(getAllCases, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const getCaseByCode = async (caseCode) => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getCaseByCode");
    formDataToSend.append("case_code", caseCode);

    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
      if (res.data.case_details) {
        const caseData = res.data.case_details;

        setUniqueNumber(caseData.code);
        setUserReportDetails(caseData);

        localStorage.setItem("caseNumber", caseData.code);
        localStorage.setItem("userReportDetails", JSON.stringify(caseData));
        localStorage.setItem("caseStatus", caseData.status);
      } else {
        console.log("No case found.");
        localStorage.removeItem("caseStatus");
      }
    } catch (error) {
      console.error("Error fetching case details:", error);
    }
  };

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const updateTrackNo = (newData) => {
    setUniqueNumber(newData);
    localStorage.setItem("caseNumber", newData);
  };

  const updateLoginFormData = (newData) => {
    setLoginFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <FormDataContext.Provider
      value={{
        formData,
        loginFormData,
        allCases,
        updateFormData,
        updateLoginFormData,
        uniqueNumber,
        setUniqueNumber,
        userReportDetails,
        setUserReportDetails,
        updateTrackNo,
        getCaseByCode,
        updateCheckboxData,
        checkboxData,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
};

export default FormDataContext;
