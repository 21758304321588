import { useEffect, useState, createContext } from "react";
import { useTranslation } from "react-i18next";

const customerContext = createContext();

const CustomerProvider = ({ children }) => {

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <customerContext.Provider
        value={{
          changeLanguage,
        }}
      >
        {children}
      </customerContext.Provider>
    </div>
  );
};

export { customerContext, CustomerProvider };
