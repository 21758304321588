import banner from "./new-rep-banner.png";
import "./NewReport.css";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormDataContext from "../../FormDataContext";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import MyModal from "../TwoScenarios/Modal";
import { IoIosArrowDropdown } from "react-icons/io";

const NewReport = () => {
  const [showModal, setShowModal] = useState(true);

  const { t, i18n } = useTranslation();
  const { updateFormData } = useContext(FormDataContext);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedClassification, setSelectedClassification] = useState("");
  const [definition, setDefinition] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    updateFormData({ company: event.target.value });
  };

  const handleClassificationChange = (event) => {
    setSelectedClassification(event.target.value);
    updateFormData({ classification: t(`${event.target.value}`) });
    setDefinition(t(`${event.target.value}Def`));
  };

  const customStyle = {
    color: "#fff",
    borderRadius: "8px",
    border: 0,
    height: "50px",
    minWidth: "130px",
    maxWidth: "180px",
    backgroundColor: "#00b0c9",
    fontSize: "1rem",
  };
  const options = Array.from(
    { length: 11 },
    (_, index) => `speakUPLabel${index + 1}`
  );

  return (
    <section className="container">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-1 order-md-0 ">
          <div className="text-center mb-3">
            <h2 className="fs-1 fw-bold secondary-color Tajawal">
              {t("newReport")}
            </h2>
          </div>

          <div className="d-flex justify-content-center gap-2 gap-md-4 Tajawal">
            <div className="custom-select ">
              <select
                required
                style={customStyle}
                onChange={handleCompanyChange}
                value={selectedCompany}
              >
                <option value="" disabled selected hidden>
                  {t("company")} <IoIosArrowDropdown />
                </option>
                <option value="MV">MV</option>
                <option value="DME">DME</option>
                <option value="DMA">DMA</option>
                <option value="DMG">DMG</option>
                <option value="new-businesses">New businesses</option>
              </select>
            </div>
            <div className="custom-select">
              <select
                required
                style={customStyle}
                onChange={handleClassificationChange}
                value={selectedClassification}
              >
                <option value="" disabled selected hidden>
                  {t("classification")} &nbsp; &nbsp;&nbsp;&nbsp;
                </option>
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {t(option)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <Link
              className="d-flex justify-content-center align-items-center text-decoration-none secondary-color tajawal"
              to="/scenario"
            >
              {i18n.language === "ar" ? (
                <CiCircleChevRight size={30} className="ri" />
              ) : (
                <CiCircleChevLeft size={30} className="ri" />
              )}
              &nbsp;{t("back")}
            </Link>
            <Link
              className="d-flex justify-content-center align-items-center text-decoration-none secondary-color tajawal"
              to={`${
                selectedCompany === "" || selectedClassification === ""
                  ? ""
                  : "/chat-page"
              }`}
            >
              {t("next")} &nbsp;
              {i18n.language === "ar" ? (
                <>
                  <CiCircleChevLeft size={30} className="ri" />
                </>
              ) : (
                <>
                  <CiCircleChevRight size={30} className="ri" />
                </>
              )}
            </Link>
          </div>
          {/* {selectedCompany === "" &&
            selectedClassification === "" &&
            "Both fields are required to proceed"} */}
          {definition && (
            <p className="mt-3 primary-color tajawal">{definition}</p>
          )}
        </div>
        <div className="col-12 col-md-6 mb-5 p-5 p-md-0 text-center">
          <img
            src={banner}
            className="img-fluid"
            alt="various phrases indicating the concept of the application"
          />
        </div>
      </div>
      <MyModal show={showModal} handleClose={handleCloseModal} />
    </section>
  );
};

export default NewReport;
