import axios from "axios";
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import { BsFilterLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import FormDataContext from "../../FormDataContext";
import { CSVLink } from "react-csv";
import Loader from "../../components/Skeleton/SkeletonLoader";

const columns = [
  {
    name: "Report No.",
    selector: (row) => row.number,

    grow: 1,
  },
  {
    name: "Company",
    selector: (row) => row.company,
  },
  {
    name: "Classification",
    selector: (row) => row.labels,
    cell: (row) => <div style={{ fontFamily: "Tajawal" }}>{row.labels}</div>,
  },
  {
    name: "Message",
    selector: (row) => row.message,
    cell: (row) => <div style={{ fontFamily: "Tajawal" }}>{row.message}</div>,
  },
  {
    name: "Received On",
    selector: (row) => row.received,
  },
];

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "rgb(226,226,226)",
      color: "var(--main-color)",
      fontSize: "14px",
      fontWeight: "bold",
      // fontFamily: "Tajawal",
    },
  },
  headRow: {
    style: {
      backgroundColor: "rgba(34, 34, 34, 0.9)",
    },
  },
};

function Dashboard() {
  const [dataTable, setDataTable] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [formattedCases, setFormattedCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { allCases } = useContext(FormDataContext);

  // start

  const classificationMapping = {
    "الاحتيال (السرقة/الرشوة/تزوير الوثائق)":
      "Fraud (Theft/Bribery/Falsifying Documentation)",
    التحرش: "Harassment",
    "إساءة استخدام السلطة": "Abuse of Power",
    "انتهاكات الصحة والسلامة": "Health & Safety Breaches",
    "سوء استغلال الأصول": "Corporate assets misuse",
    "استخدام المخدرات والكحول": "Alcohol & Drug Use",
    "الواسطة/التحيز": "Nepotism/Favoritism",
    "التمييز (الدين/الجنس/الجنسية/الإعاقة/الطبقة الاجتماعية)":
      "Discrimination (Religion/Gender/Nationality/Disability/Socioeconomic Class)",
    "الاحتقار/التقليل/التنمر/السخرية":
      "Disrespect (Belittling/Bullying/Sarcasm/Humiliation/Inappropriate Feedback)",
    "تضارب المصالح (للاستفادة من وظيفته)": "Conflict of Interest",
    "انتهاك السرية": "Confidentiality Breach",
  };

  const [clickedRowId, setClickedRowId] = useState(null);
  const handleRowClick = (row) => {};

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgb(226,226,226)",
        color: "var(--main-color)",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    headRow: {
      style: {
        backgroundColor: "rgba(34, 34, 34, 0.9)",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.id === clickedRowId,
      style: {
        fontWeight: "normal",
      },
    },
  ];
  // end

  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const getAllCases = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getAllCases");
    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
      const casesArray = res?.data?.cases || [];

      // Transform the data to the desired format
      const newCases = casesArray.map((caseItem, index) => ({
        id: index + 1,
        number: caseItem.code,
        company: caseItem.company,
        message: caseItem.message,
        assignee_name: caseItem.asignee || "N/A",
        labels:
          classificationMapping[caseItem.classification] ||
          caseItem.classification, // ✅ Translate Classification
        received: caseItem.creation_date,
      }));

      setFormattedCases(newCases);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCases();
  }, []);

  useEffect(() => {
    let filteredData = formattedCases;

    // Apply search query filter
    if (query) {
      filteredData = filteredData.filter((row) =>
        Object.values(row).some((val) =>
          String(val).toLowerCase().includes(query.toLowerCase())
        )
      );
    }

    // Apply selected filter
    if (selectedFilter) {
      filteredData = filteredData.sort((a, b) => {
        if (a[selectedFilter] < b[selectedFilter]) return -1;
        if (a[selectedFilter] > b[selectedFilter]) return 1;
        return 0;
      });
    }

    setDataTable(filteredData);
  }, [query, selectedFilter, formattedCases]);

  const handleRowClicked = (row, event) => {
    setClickedRowId(row.id);
    navigate(`/admin/cases/${row.number}`);
  };

  return (
    <section>
      <div className="container px-3">
        <div className="row d-flex justify-content-between align-items-center flex-row">
          <div className="col-4">
            <h2 className="py-4 primary-color fw-bold">Cases</h2>
          </div>
          <div className=" col-4">
            <CSVLink
              filename="SpeakUp_Data.csv"
              asyncOnClick={true}
              className="button border-0 text-white submit-btn  rounded-1 fs-6 py-2 px-3 text-decoration-none"
              data={dataTable}
            >
              <span>Export Data</span>
            </CSVLink>
          </div>
          <div className="col-4 text-end">
            <div className="input-group rounded">
              <input
                type="search"
                className="form-control rounded border-0 primary-color"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={query}
                onChange={handleSearchChange}
              />
              <span
                className="input-group-text border-0 primary-color"
                id="search-addon"
              >
                <BsSearch />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader size={50} />
          </div>
        ) : (
          <DataTable
            data={dataTable}
            onRowClicked={handleRowClicked}
            conditionalRowStyles={conditionalRowStyles}
            responsive
            columns={columns}
            highlightOnHover
            pointerOnHover
            pagination
            customStyles={customStyles}
          />
        )}
      </div>
    </section>
  );
}

export default Dashboard;
