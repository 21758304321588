import React, { useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormDataContext from "../../FormDataContext";
import codeOFConduct from "../../assets/Code of Conduct (DMG) Updated .pdf";
import terms from "../../assets/DMG - Speak Up Policy Arabic, English Jul21.pdf";

const MyModal = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const { updateCheckboxData } = useContext(FormDataContext);

  const [checkboxes, setCheckboxes] = useState({
    feedback_manager: false,
    feedback_Team: false,
    read_conduct: false,
    have_read: false,
  });

  const direction = i18n.language === "ar" ? "rtl" : "ltr";

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;

    if (id === "have_read") {
      setCheckboxes({
        feedback_manager: checked,
        feedback_Team: checked,
        read_conduct: checked,
        have_read: checked,
      });
    } else {
      setCheckboxes((prev) => ({
        ...prev,
        [id]: checked,
      }));
    }
  };

  const isButtonDisabled =
    !checkboxes.feedback_manager ||
    !checkboxes.feedback_Team ||
    !checkboxes.read_conduct;

  const handleSubmit = () => {
    console.log("✅ Checkboxes Before Updating Context:", checkboxes);
    handleClose();
  };

  return (
    <div style={{ direction }}>
      <Modal
        show={show}
        onHide={() => {}}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
      >
        <form dir={direction}>
          <div className="p-3">
            <div className="p-3 px-4">
              <h6 className="primary-color mb-3 fw-bold">{t("modalTitle")}</h6>

              {/* ✅ First Checkbox */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="feedback_manager"
                  checked={checkboxes.feedback_manager}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label primary-color"
                  htmlFor="feedback_manager"
                >
                  {t("modalCheckOne")}
                </label>
              </div>

              {/* ✅ Second Checkbox */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="feedback_Team"
                  checked={checkboxes.feedback_Team}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label primary-color"
                  htmlFor="feedback_Team"
                >
                  {t("modalCheckTwo")}
                </label>
              </div>

              {/* ✅ Third Checkbox */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="read_conduct"
                  checked={checkboxes.read_conduct}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label primary-color"
                  htmlFor="read_conduct"
                >
                  {t("modalCheckFour")}
                  <span>
                    <a
                      style={{ fontSize: "12px" }}
                      href={codeOFConduct}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("modalCheckFourLink")}
                    </a>
                  </span>
                </label>
              </div>

              <br />

              {/* ✅ Last Checkbox: Checking it selects all others */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="have_read"
                  checked={checkboxes.have_read}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label primary-color"
                  htmlFor="have_read"
                >
                  {t("modalCheckFive")}
                  <br />
                  <span>
                    <a
                      style={{ fontSize: "12px" }}
                      href={terms}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("modalCheckFiveLink")}
                    </a>
                  </span>
                </label>
              </div>
            </div>
          </div>

          {/* ✅ Buttons */}
          <div className="d-flex justify-content-center align-items-center gap-3 mb-3">
            <Link to="/">
              <Button
                className="fw-bold border-0"
                style={{ backgroundColor: "var(--main-color2)" }}
                onClick={handleClose}
              >
                {t("backToHomepage")}
              </Button>
            </Link>

            <Button
              className="fw-bold border-0"
              style={{
                backgroundColor: isButtonDisabled
                  ? "gray"
                  : "var(--main-color2)",
              }}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              {t("makeNewReport")}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default MyModal;
