import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import { BsFilterLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import OTPInput from "./OTPInput";
import { Container, Typography, Box, Button } from "@mui/material";
import axios from "axios";
import { CSVLink } from "react-csv";
import Loader from "../../components/Skeleton/SkeletonLoader";
import CaseDetails from "../CaseDetails/CaseDetails";
const columns = [
  {
    name: "Report No.",
    selector: (row) => row.number,
  },
  {
    name: "Received on",
    selector: (row) => row.received_on,
  },
  {
    name: "Acknowledged on",
    selector: (row) => row.acknowledged_on,
  },
  {
    name: "Company",
    selector: (row) => row.company,
  },
  {
    name: "Status",
    selector: (row) => row.status,
  },
  {
    name: "Assignee Name",
    selector: (row) => row.assignee,
  },
  {
    name: "Classification",
    selector: (row) => row.labels,
  },
];

const data = [];

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "rgb(226,226,226)",
      color: "var(--main-color)",
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  headRow: {
    style: {
      backgroundColor: "rgba(34, 34, 34, 0.9)",
    },
  },
};
function Dashboard() {
  const [dataTable, setDataTable] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [formattedCases, setFormattedCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initialize isLoading to true

  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  useEffect(() => {
    let filteredData = data;

    if (query) {
      filteredData = filteredData.filter((row) =>
        Object.values(row).some((val) =>
          String(val).toLowerCase().includes(query.toLowerCase())
        )
      );
    }

    if (selectedFilter) {
      filteredData = filteredData.sort((a, b) => {
        if (a[selectedFilter] < b[selectedFilter]) return -1;
        if (a[selectedFilter] > b[selectedFilter]) return 1;
        return 0;
      });
    }

    setDataTable(filteredData);
  }, [query, selectedFilter]);

  const handleRowClicked = (row, event) => {
    // console.log(row.number);
    navigate(`/admin/cases/${row.number}`);
  };

  const getAllCases = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getAllCases");
    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
      // // console.log(res?.data.cases.map(case => case.name));
      const casesArray = res?.data?.cases || [];

      const newCases = casesArray.map((caseItem, index) => ({
        id: index + 1,
        number: caseItem.code,
        company: caseItem.company,
        status: caseItem.status,
        received_on: caseItem.creation_date,
        acknowledged_on: caseItem.acknowledge_date,
        assignee: caseItem.asignee || "N/A",
        message: caseItem.message,
        labels: caseItem.classification,
      }));
      setFormattedCases(newCases);
      setIsLoading(false);
    } catch (error) {
      // console.log(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCases();
  }, []);
  useEffect(() => {
    let filteredData = formattedCases;

    // Apply search query filter
    if (query) {
      filteredData = filteredData.filter((row) =>
        Object.values(row).some((val) =>
          String(val).toLowerCase().includes(query.toLowerCase())
        )
      );
    }

    // Apply selected filter
    if (selectedFilter) {
      filteredData = filteredData.sort((a, b) => {
        if (a[selectedFilter] < b[selectedFilter]) return -1;
        if (a[selectedFilter] > b[selectedFilter]) return 1;
        return 0;
      });
    }

    setDataTable(filteredData);
  }, [query, selectedFilter, formattedCases]);

  return (
    <section>
      <div className="container px-3">
        <div className="row d-flex justify-content-between align-items-center flex-row">
          <div className="col-6">
            <h2 className="py-4 primary-color fw-bold">Dashboard</h2>
          </div>
          <div className="col-4 text-end">
            <div className="input-group rounded">
              <input
                type="search"
                className="form-control rounded border-0"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={query}
                onChange={handleSearchChange}
              />
              <span className="input-group-text border-0" id="search-addon">
                <BsSearch />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader size={50} />
          </div>
        ) : (
          <DataTable
            data={dataTable}
            onRowClicked={handleRowClicked}
            responsive
            columns={columns}
            highlightOnHover
            pointerOnHover
            pagination
            customStyles={customStyles}
          />
        )}
      </div>
    </section>
  );
}
export default Dashboard;
