import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { BsListCheck } from "react-icons/bs";

import { LuArrowDownUp } from "react-icons/lu";
import { useEffect, useState } from "react";
import axios from "axios";
import { log } from "tone/build/esm/core/util/Debug";

const Analysis = () => {
  const data = [
    {
      name: "discrimination",
      status: "closed",
      cases: 1,
      company: "DMA",
      amt: 2,
    },

    {
      name: "Fraud",
      status: "closed",
      cases: 5,
      company: "MV",
      amt: 1,
    },

    {
      name: "Harassment",
      status: "new",
      cases: 9,
      company: "DME",
      amt: 2,
    },
    {
      name: "Drugs",
      status: "closed",
      cases: 9,
      company: "DME",
      amt: 3,
    },
    {
      name: "Bullying",
      status: "in_progress",
      cases: 3,
      company: "DMA",
      amt: 9,
    },
  ];

  const [newReportStatus, setNewReportStatus] = useState([]);
  const [closedReportStatus, setClosedReportStatus] = useState([]);
  const [evidenceReportStatus, setevidenceReportStatus] = useState([]);

  const [inProgressReportStatus, setInProgressReportStatus] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [classData, setClassData] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [status, setStatus] = useState("");
  const [caseReport, setCaseReport] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [mainFilter, setMainFilter] = useState("classification");

  const [isNewSelected, setIsNewSelected] = useState(false);
  const [cases, setCases] = useState([]);
  const [counts, setCounts] = useState({
    new: [
      { company: "DMA", count: 0 },
      { company: "DME", count: 0 },
      { company: "MV", count: 0 },
    ],
    in_progress: [
      { company: "DMA", count: 0 },
      { company: "DME", count: 0 },
      { company: "MV", count: 0 },
    ],
    closed: [
      { company: "DMA", count: 0 },
      { company: "DME", count: 0 },
      { company: "MV", count: 0 },
    ],
  });
  const [companyReport, setCompanyReport] = useState([]);
  const [assignees, setAssignees] = useState([]); // ✅ State to store assignees
  const [allAssignTickets, setAllAssignTickets] = useState([]); // ✅ State to store assignees

  const fetchTopAssignees = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getTopAssignees");

    try {
      const response = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );

      // ✅ Ensure `assignees` key exists before updating state
      if (response?.data?.company_report) {
        let allAssignee = response.data.company_report;
        let assigneeArra = [];
        let allAssignedTickets = 0;
        allAssignee.map((assignData, index) => {
          assigneeArra.push(
            <div className="d-flex justify-content-between align-items-center">
              <p>{assignData.name}</p>
              <p>{assignData.count}</p>
            </div>
          );
          allAssignedTickets += assignData.count;
        });
        setAssignees(assigneeArra);
        setAllAssignTickets(allAssignedTickets);
      } else {
        console.error("API response does not contain 'assignees'");
      }
    } catch (error) {
      console.error("Error fetching top assignees:", error);
    }
  };

  useEffect(() => {
    fetchTopAssignees();
  }, []);

  function handleChangeStatus(e) {
    const selectedStatus = e.target.value;
    setStatusFilter(selectedStatus);

    switch (selectedStatus) {
      case "new":
        setClassData(newReportStatus);
        break;
      case "in_progress":
        console.log("🔍 Setting in-progress data:", inProgressReportStatus);
        setClassData(inProgressReportStatus);
        break;
      case "closed":
        console.log("🔍 Setting closed data:", closedReportStatus);
        setClassData(closedReportStatus);
        break;
      case "evidence_required":
        console.log("🔍 Setting evidence-required data:", evidenceReportStatus);
        setClassData(evidenceReportStatus);
        break;
      default:
        console.warn("⚠️ No matching filter for status:", selectedStatus);
        break;
    }
  }

  function handleChangeMainFilter(e) {
    setMainFilter(e.target.value);
  }
  const getBarCountData = async (filterType) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("data_type", filterType);
      const response = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );

      const responseKey =
        mainFilter === "company"
          ? "company_report"
          : mainFilter === "classification"
          ? "classification_report"
          : "assignee_report";

      if (!response?.data[responseKey]) {
        console.warn(`⚠️ No data found for '${responseKey}'`);
        return;
      }

      setClassData(response.data[responseKey]);
    } catch (error) {
      console.error("❌ Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (statusFilter === "all") {
      // console.log("sdsadsdsdssdsf");
      getBarCountData(
        mainFilter === "company"
          ? "getAbalysisByComp"
          : mainFilter === "classification"
          ? "getAbalysisByClass"
          : "getAbalysisByAssignee"
      );
    } else {
      if (mainFilter === "company") {
        console.log("object is not classification");
      }
    }
  }, [statusFilter, mainFilter]);
  useEffect(() => {
    console.log(classData, "DDDD");
  }, [classData]);

  useEffect(() => {
    //  data_type ==> getAbalysisByClass
    //  for companies reports ==> getAbalysisByComp
    const getAllCases = async () => {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("data_type", "getAbalysisByComp");
        const response = await axios.post(
          "https://speakup.mountainviewegypt.com/__API/index.php",
          formDataToSend
        );
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    getAllCases();
  }, []);

  function handleCaseReportChange(e) {
    setCaseReport(e.target.value);
  }

  const getAllCases = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getAllCases");
    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );

      setAllClasses(res?.data.cases);
      setCases(res?.data.cases);
    } catch (error) {
      // console.log(error.message);
    }
  };

  const data2 = [
    {
      name: "2024",
      cases: " ",
      company: "DME",
      amt: 3,
    },
    {
      name: "2025",
      cases: `${allClasses?.length}`,
      company: "DME",
      amt: 3,
    },
  ];

  useEffect(() => {
    getAllCases();
  }, []);
  useEffect(() => {
    // console.log("allCases", allClasses);
  }, [allClasses]);

  // Double filter START
  useEffect(() => {
    if (statusFilter !== "all") {
      if (mainFilter === "company") {
        const statusCounts = {
          new: [
            { company: "DMA", count: 0 },
            { company: "DME", count: 0 },
            { company: "MV", count: 0 },
          ],
          in_progress: [
            { company: "DMA", count: 0 },
            { company: "DME", count: 0 },
            { company: "MV", count: 0 },
          ],
          closed: [
            { company: "DMA", count: 0 },
            { company: "DME", count: 0 },
            { company: "MV", count: 0 },
          ],
        };

        cases?.forEach((caseItem) => {
          const { status, company } = caseItem;
          console.log(status, company);
          // console.log(statusCounts[status][company]);
          const statusArray = statusCounts[status];
          const companyObj = statusArray?.find(
            (item) => item.company === company
          );
          if (companyObj) {
            companyObj.count += 1;
          } else {
            console.log(`Company ${company} not found in status ${status}`);
          }
          // statusCounts[status][company][count]++;
        });

        setCounts(statusCounts);
      }
      // const labels = cases.map((case) => {
      //   return case.classification
      // });

      if (mainFilter === "classification") {
        const labels = cases?.map((item) => {
          if (item.status === statusFilter) {
            return item.classification;
          }
        });

        console.log("labelss", labels);
        const uniqueLabels = new Set(labels);
        uniqueLabels.delete(undefined);
        // Using reduce to create a map of unique values with their counts
        const labelCounts = Array.from(uniqueLabels).reduce((acc, label) => {
          acc[label] = labels.filter((item) => item === label).length;
          return acc;
        }, {});
        const transformedData = Object.entries(labelCounts).map(
          ([classification, count]) => ({
            classification,
            count,
          })
        );

        setCounts(transformedData);
      }
    }
  }, [statusFilter, mainFilter]);
  // console.log("counts", counts);
  useEffect(() => {
    console.log(counts, "Cccccccc");
    setClassData(
      mainFilter === "classification" ? counts : counts[statusFilter]
    );
  }, [counts]);

  useEffect(() => {
    const generateCompanyReport = (counts) => {
      const report = [];
      Object.keys(counts)?.forEach((status) => {
        Object.keys(counts[status])?.forEach((company) => {
          const count = counts[status][company];
          report.push({ status, company, count });
        });
      });
      // console.log("reporttttt", report);
      return report;
    };

    const report = generateCompanyReport(counts);
    const newReportStatus = report?.filter(
      (reportItem) => reportItem.status === "new"
    );
    newReportStatus?.forEach((item) => {
      delete item["status"];
    });
    setNewReportStatus(newReportStatus);

    const inProgressReportStatus = report?.filter(
      (reportItem) => reportItem.status === "in_progress"
    );
    setInProgressReportStatus(inProgressReportStatus);

    const closedReportStatus = report?.filter(
      (reportItem) => reportItem.status === "closed"
    );
    const evidenceReportStatus = report?.filter(
      (reportItem) => reportItem.status === "evidence_required"
    );
    setevidenceReportStatus(evidenceReportStatus);

    setClosedReportStatus(closedReportStatus);

    // console.log(newReportStatus, inProgressReportStatus, closedReportStatus);

    setCompanyReport(report);
  }, [counts]);

  useEffect(() => {
    const generateCompanyReport = (counts) => {
      const report = [];
      Object.keys(counts)?.forEach((status) => {
        Object.keys(counts[status])?.forEach((company) => {
          const count = counts[status][company];
          report.push({ company, count });
        });
      });
      return report;
    };

    const report = generateCompanyReport(counts);
    setCompanyReport(report);
  }, [counts]);
  return (
    <section className="container">
      <div className="row ">
        <h2 className="py-2 primary-color fw-bold">Analysis</h2>
      </div>
      <div className="row pe-3">
        <h4 className="bg-secondary text-white p-3" style={{ width: "700px" }}>
          Cases Overview
        </h4>
      </div>
      <div className="row">
        <div className="col-3 white-bg">
          <div className="row ">
            <p
              className="p-2 px-3 text-white"
              style={{ backgroundColor: "var(--main-color)" }}
            >
              Assignee &nbsp; <LuArrowDownUp /> &nbsp;Number of issues
            </p>
          </div>
          <div className="row px-2">
            {assignees}
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <p></p>
              <p>{allAssignTickets}</p>
            </div>
          </div>
          <div className="row white-bg mb-4">
            <div className="analysis-bg p-3 mb-4">
              <div className="">
                <h5>Yearly/Quarterly Report</h5>
              </div>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={data2}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                  barSize={30}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="cases" fill="#001a70">
                    <LabelList dataKey="cases" position="insideTop" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="col-5">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={classData}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
              barSize={30}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={mainFilter} />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#001a70">
                <LabelList dataKey="count" position="insideTop" />
              </Bar>
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="col-4">
          <div className="row white-bg mb-4">
            <div className="analysis-bg p-3 mb-4">
              <div className="d-flex flex-row justify-content-between align-items-center px-4">
                <div className="h5">Cases status</div>
                <div className="h5">
                  Filter by{" "}
                  <span>
                    {" "}
                    <BsListCheck />{" "}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <select
                className="mb-3 form-select"
                aria-label="Default select example"
                value={statusFilter}
                onChange={handleChangeStatus}
              >
                <option value="all" selected>
                  All
                </option>
                <option value="closed">Closed</option>
                <option value="in_progress">In progress</option>
                <option value="new">New</option>
                <option value="evidence_reuqire">Evidence Require </option>
              </select>
            </div>
          </div>
          <div className="row white-bg">
            <div className="analysis-bg p-3 mb-4">
              <div className="d-flex flex-row justify-content-between align-items-center px-4">
                <div className="h5">Cases report</div>
                <div className="h5">
                  Filter by{" "}
                  <span>
                    {" "}
                    <BsListCheck />{" "}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <select
                className="mb-3 form-select"
                aria-label="Default select example"
                value={mainFilter}
                onChange={handleChangeMainFilter}
              >
                <option value="" disabled selected hidden>
                  Status
                </option>
                <option value="assignee">Assignee</option>
                <option value="classification">Classification</option>
                <option value="company">Company</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <h2>Case Status Counts</h2>
        <table>
          <thead>
            <tr>
              <th>Status</th>
              <th>DME</th>
              <th>MV</th>
              <th>DMA</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(counts).map((status) => (
              <tr key={status}>
                <td>{status}</td>
                <td>{counts[status].DME}</td>
                <td>{counts[status].MV}</td>
                <td>{counts[status].DMA}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h2>Company Report</h2>
        <table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Company</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {companyReport.map((report, index) => (
              <tr key={index}>
                <td>{report.status}</td>
                <td>{report.company}</td>
                <td>{report.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </section>
  );
};

export default Analysis;
