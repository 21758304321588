import React from "react";
import { Spinner } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import "./skeleton.scss";

const MyLoader = (props) => (
  <div className=" d-flex flex-column align-items-center">
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
    <Skeleton animation="wave" width={800} height={40} />
  </div>
);

export default MyLoader;
