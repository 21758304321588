import { NavLink, useNavigate } from "react-router-dom";
import banner from "./assets/login.png";
import "./admin.css";
import logo from "../../components/assets/logo.png";
import { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login, isAuthorized, setIsAuthorized, x } = useAuth();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create form data
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "loginAsAdmin");
    formDataToSend.append("email", email);
    formDataToSend.append("password", password);

    try {
      // Send the form data to the server
      const response = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
      // Check the response from the server
      if (response.data.logged_in === true) {
        login();
        localStorage.setItem("user", "ok");
        navigate("/admin");
        // setIsAuthorized(true);
      } else {
        setError("Invalid login credentials");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error(error);
    }

  };
  return (
    <>
      <section className="container  p-5">
        <div>
          <NavLink to="/">
            <img src={logo} className="img-fluid" alt="Speak up logo" />
          </NavLink>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-1 order-md-0 ">
            <div className="text-center mb-3">
              {/* <img
              src={icon}
              width={120}
              height={120}
              className="img-fluid"
              alt="Edit Icon"
            /> */}
            </div>
            <h3 className="primary-color display-3 fw-bolder ">Log in</h3>
            <form onSubmit={handleSubmit} className="mt-3">
              <div className=" d-flex justify-content-center align-items-center flex-column  text-center">
                <input
                  type="email"
                  className="form-control w-50 rounded-pill name "
                  id="name"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  placeholder="Email"
                />
                <br />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control w-50 rounded-pill password "
                  id="password"
                  name="password"
                  placeholder="Password"
                />
                <br />
                <button
                  type="submit"
                  className="fs-3 border-0 login-btn p-2 px-4 pb-1 rounded-3 text-white "
                >
                  Sign in
                </button>
              </div>
              {error && <p>{error}</p>}
            </form>
          </div>
          <div className="col-12 col-md-6 mb-5 p-5 p-md-0  text-center">
            <img
              src={banner}
              className="img-fluid"
              alt="various phrases indicating the concept of the application"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
