import { BsReply } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { IoDocumentAttachOutline } from "react-icons/io5";
import DataTable from "react-data-table-component";
import { IoMdDocument } from "react-icons/io";
import { BsTrash3Fill } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { CgAttachment } from "react-icons/cg";
import { Form } from "react-bootstrap";
import { FaCirclePlay } from "react-icons/fa6";
import axios from "axios";
import fileDownload from "js-file-download";

const Case = () => {
  const [message, setMessage] = useState("");
  const [showReply, setShowReply] = useState(false);
  const [showFormSubmitted, setShowFormSubmitted] = useState(false);
  const [showFormAignee, setshowFormAignee] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [caseDetails, setCaseDetails] = useState({});
  const [assignees, setAssignees] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [checkbox, setcheckbox] = useState(
    caseDetails?.status === "evidence_required" ? true : false
  );
  const API_URL = "https://speakup.mountainviewegypt.com/__API/index.php";

  useEffect(() => {
    setcheckbox(caseDetails?.status === "evidence_required" ? true : false);
  }, [caseDetails]);

  const inputRef = useRef();
  let { no } = useParams();

  const BASE_URL = "https://dmgian.corp-dmg.com/_speakup_api/";

  const downloadFile = (filePath) => {
    if (!filePath) {
      console.warn("⚠️ No file available for download.");
      return;
    }

    axios
      .get(filePath, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath.split("/").pop());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error(" Error downloading file:", error);
      });
  };

  const getFileName = (filePath) => {
    if (
      filePath?.startsWith(
        "https://speakup.mountainviewegypt.com/documents/request_attachments/"
      )
    ) {
      return filePath.replace("./request_attachments/", "");
    } else if (filePath?.startsWith("./request_records/")) {
      return filePath.replace("./request_records/", "");
    }
    return filePath || "Unknown File";
  };

  const getFileType = (fileName) => {
    return fileName ? fileName.split(".").pop() : "Unknown Type";
  };

  const fileName = getFileName(caseDetails?.file);
  const recordName = getFileName(caseDetails?.record);
  const fileType = getFileType(fileName);

  const columns = [
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Received on",
      selector: (row) => row.received_on,
    },
    {
      name: "Audio",
      selector: (row) => row.audio,
      sortable: false,
    },
  ];
  const data = [
    {
      id: 1,
      name: caseDetails?.file ? (
        <>
          <IoMdDocument size={20} /> {getFileName(caseDetails.file)}
        </>
      ) : (
        <span className="text-danger">No Attached Documents</span>
      ),
      type: caseDetails?.file ? "File" : " ",
      received_on: caseDetails?.file ? caseDetails.creation_date : " ",
      audio: caseDetails?.file ? (
        <FiDownload
          size={20}
          onClick={() => viewFile(caseDetails?.file)}
          cursor="pointer"
          style={{ color: "var(--main-color2)" }}
        />
      ) : null,
    },
    {
      id: 2,
      name: caseDetails?.record ? (
        <>
          <FaCirclePlay size={20} /> {getFileName(caseDetails.record)}
        </>
      ) : (
        <span className="text-danger">No Attached Record</span>
      ),
      type: caseDetails?.record ? "Audio" : " ",
      received_on: caseDetails?.record ? caseDetails.creation_date : " ",
      audio: caseDetails?.record ? (
        <audio controls>
          <source src={caseDetails.record} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      ) : null,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgb(226,226,226)",
        color: "var(--main-color)",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    headRow: {
      style: {
        backgroundColor: "rgba(34, 34, 34, 0.9)",
      },
    },
  };

  const getCase = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getCaseByCode");
    formDataToSend.append("case_code", no);

    try {
      const res = await axios.post(API_URL, formDataToSend);

      if (res.data?.case_details) {
        const caseData = res.data.case_details;

        caseData.file =
          caseData.file && caseData.file !== "NULL" ? caseData.file : null;
        caseData.record =
          caseData.record && caseData.record !== "NULL"
            ? `https://speakup.mountainviewegypt.com/${caseData.record.replace(
                "../",
                ""
              )}`
            : null;

        setCaseDetails(caseData);
      } else {
        console.warn("⚠️ Case details not found!", res.data);
      }
    } catch (error) {
      console.error("❌ Error fetching case:", error.message);
    }
  };

  const buttonRef = useRef(null);

  const createRipple = (event) => {
    const button = buttonRef.current;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    const rect = button.getBoundingClientRect();
    const top = event.clientY - rect.top - radius;
    const left = event.clientX - rect.left - radius;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${left}px`;
    circle.style.top = `${top}px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
    setShowReply((prevState) => !prevState);
    getCase();
  };

  const handleTextareaChange = (event) => {
    setMessage(event.target.value);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    if (inputRef?.current?.files.length > 0) {
      const file = inputRef.current.files[0];
      setUploadedFile(file);
      setUploadedFileName(file.name);
    }
  };
  const textareaStyle = {
    resize: "none",
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  function handleSubmit(event) {
    event.preventDefault();
  }

  const getAllAssignees = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getAssignees");
    try {
      const res = await axios.post(API_URL, formDataToSend);
      setAssignees(res?.data.assignees);
    } catch (error) {
      console.log(error.message);
    }
  };
  const changeAssignee = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "changeCaseAsignee");
    formDataToSend.append("case_code", no);
    formDataToSend.append("old_assignee", caseDetails?.asignee);
    formDataToSend.append("new_assignee", selectedOption);

    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
      setCaseDetails((prev) => ({
        ...prev,
        asignee: selectedOption,
      }));
    } catch (error) {
      // console.error("Error updating assignee:", error);
    }
  };
  const handleCheckboxChange = (e) => {
    setcheckbox(e.target.checked);
  };

  const closeCase = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "closeCurrentCase");
    formDataToSend.append("case_code", no);
    formDataToSend.append("current_assignee", caseDetails?.asignee);

    try {
      const res = await axios.post(API_URL, formDataToSend);
      getCase();
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleAdminReply(e) {
    e.preventDefault();
    setMessage("");
    setShowFormSubmitted(true);
    setShowReply(false);
    setTimeout(() => setShowFormSubmitted(false), 2000);
    adminReply();
  }

  const adminReply = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "updateCaseByCode");
    formDataToSend.append("case_code", no);
    formDataToSend.append("message", message);
    formDataToSend.append("evidenceRequired", checkbox);

    if (inputRef.current?.files[0]) {
      formDataToSend.append("file", inputRef.current.files[0]);
    }

    try {
      const res = await axios.post(API_URL, formDataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Full API Response:", res.data);

      getCase();
    } catch (error) {
      console.error("Error sending file:", error.message);
    }
  };

  ///////////////////
  function handleSubmitNewAss(event) {
    event.preventDefault();
    changeAssignee();
    setshowFormAignee(true);
    setTimeout(() => {
      setshowFormAignee(false);
    }, 2000);
  }
  const viewFile = (filePath) => {
    if (!filePath) {
      console.warn("⚠️ No file available to view.");
      return;
    }

    const newWindow = window.open(filePath, "_blank");
  };

  useEffect(() => {
    getAllAssignees();
    getCase();
  }, []);

  return (
    <section className="container mb-5">
      <div className="row">
        <div className="col-9 pe-4">
          <div className="row">
            <h2 className="py-2 primary-color fw-bold">Cases</h2>
          </div>
          <div className="row mb-3">
            <div className="col-4 white-bg d-flex align-items-center">
              <p className="m-2 fw-bold ">
                <strong>Report Number: &nbsp;&nbsp; {caseDetails?.code}</strong>
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="p-0">
              <div className="accordion" id="message">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Message
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show px-5 white-bg"
                    data-bs-parent="#message"
                  >
                    <div className="accordion-body px-5 Tajawal">
                      {caseDetails?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {caseDetails?.reply && (
            <div className="row mb-3">
              <div className="p-0">
                <div className="accordion" id="reply">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseAdminReply"
                        aria-expanded="true"
                        aria-controls="collapseAdminReply"
                      >
                        Admin Response
                      </button>
                    </h2>
                    <div
                      id="collapseAdminReply"
                      className="accordion-collapse collapse show px-5 white-bg"
                      data-bs-parent="#reply"
                    >
                      <div className="accordion-body px-5 Tajawal">
                        <p>{caseDetails?.reply}</p>

                        {caseDetails?.reply_file &&
                          caseDetails.reply_file !== "NULL" && (
                            <div className="mt-3">
                              <button
                                className="btn border-0 p-1 px-3 rounded-pill text-white"
                                style={{
                                  backgroundColor: `var(--main-color2)`,
                                }}
                                onClick={() => viewFile(caseDetails.reply_file)}
                              >
                                <FiDownload size={20} /> View Attached File
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {caseDetails?.comment && (
            <div className="row mb-3">
              <div className="p-0">
                <div className="accordion" id="comment">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne-sub"
                        aria-expanded="true"
                        aria-controls="collapseOne-sub"
                      >
                        User Reply
                      </button>
                    </h2>
                    <div
                      id="collapseOne-sub"
                      className="accordion-collapse collapse show px-5 white-bg"
                      data-bs-parent="#comment"
                    >
                      <div className="accordion-body px-5 Tajawal">
                        {caseDetails?.comment}
                        {/* ✅ Show User-Uploaded File as a Download Button */}
                        {caseDetails?.comment_file &&
                          caseDetails.comment_file !== "NULL" && (
                            <div className="mt-3">
                              <button
                                className="btn border-0 p-1 px-3 rounded-pill text-white"
                                style={{
                                  backgroundColor: `var(--main-color2)`,
                                }}
                                onClick={() =>
                                  viewFile(caseDetails.comment_file)
                                }
                              >
                                <FiDownload size={20} /> View Attached File
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {caseDetails?.final_reply && (
            <div className="row mb-3">
              <div className="p-0">
                <div className="accordion" id="reply">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseAdminReply"
                        aria-expanded="true"
                        aria-controls="collapseAdminReply"
                      >
                        Final Admin Response
                      </button>
                    </h2>
                    <div
                      id="collapseAdminReply"
                      className="accordion-collapse collapse show px-5 white-bg"
                      data-bs-parent="#reply"
                    >
                      <div className="accordion-body px-5 Tajawal">
                        <p>{caseDetails?.final_reply}</p>

                        {caseDetails?.reply_file &&
                          caseDetails.reply_file !== "NULL" && (
                            <div className="mt-3">
                              <button
                                className="btn border-0 p-1 px-3 rounded-pill text-white"
                                style={{
                                  backgroundColor: `var(--main-color2)`,
                                }}
                                onClick={() => viewFile(caseDetails.final_file)}
                              >
                                <FiDownload size={20} /> View Attached File
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row ">
            <div className="p-0">
              <div className="accordion" id="document">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      Document
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show  white-bg"
                    data-bs-parent="#document"
                  >
                    <div className="accordion-body  ">
                      <div className="row">
                        <div className="d-flex flex-row gap-2 align-items-center justify-content-end">
                          <div>
                            {!caseDetails?.comment &&
                            caseDetails.status === "evidence_required" ? (
                              <p className="text-warning fw-bold">
                                Awaiting user evidence submission...
                              </p>
                            ) : (
                              <button
                                className="awesome-button"
                                onClick={() => setShowReply(true)}
                                hidden={
                                  caseDetails?.status === "closed" ||
                                  caseDetails.final_reply
                                }
                              >
                                <BsReply size={20} /> Reply to message
                              </button>
                            )}
                          </div>
                        </div>
                        <div>
                          {showReply && (
                            <form onSubmit={handleAdminReply} className="mt-3">
                              <Form.Group controlId="textarea">
                                <Form.Control
                                  as="textarea"
                                  className="textare p-3"
                                  rows={7}
                                  style={textareaStyle}
                                  placeholder="write a message"
                                  onChange={handleTextareaChange}
                                  value={message}
                                />
                              </Form.Group>
                              {!caseDetails?.reply ? (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    checked={checkbox}
                                    id="flexCheckDefault"
                                    onClick={handleCheckboxChange}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Evidence Required
                                  </label>
                                </div>
                              ) : (
                                " "
                              )}
                              <div className="mt-3 d-flex justify-content-between align-items-center px-3 mb-2">
                                <span>You can also attach photos / files</span>
                                <span>
                                  <span>
                                    <input
                                      type="file"
                                      ref={inputRef}
                                      className="d-none"
                                      onChange={handleDisplayFileDetails}
                                    />
                                    <button
                                      className="btn"
                                      onClick={handleUpload}
                                    >
                                      <span>{uploadedFileName}</span>
                                      <span>
                                        <CgAttachment
                                          cursor="pointer"
                                          size={30}
                                          className="chat-icon"
                                        />
                                      </span>
                                    </button>
                                  </span>
                                  <button
                                    type="submit"
                                    className={`text-decoration-none button  rounded-3 text-white py-2 px-2  submit-btn ${
                                      !message.trim() ? "disabled" : ""
                                    }`}
                                  >
                                    Send
                                  </button>
                                </span>
                              </div>
                            </form>
                          )}
                          <div>
                            {showFormSubmitted && (
                              <p className="text-success text-end py-2">
                                Sent successfully
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <DataTable
                          data={data}
                          responsive
                          columns={columns}
                          highlightOnHover
                          pointerOnHover
                          customStyles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 white-bg ">
          <div className="white-bg mt-5 mb-3 d-flex flex-row justify-content-around align-items-center">
            <span
              className="border-0 p-2 text-white"
              style={{ backgroundColor: `var(--main-color)` }}
            >
              {caseDetails?.status}
            </span>
            &nbsp;&nbsp;&nbsp;
            {caseDetails?.status !== "closed" && (
              <div className="text-end">
                <button
                  onClick={() => {
                    closeCase();
                  }}
                  className=" border-0 p-1 px-3 rounded-pill text-white"
                  style={{ backgroundColor: `var(--main-color2)` }}
                >
                  Close
                </button>
              </div>
            )}
          </div>
          <form onSubmit={handleSubmitNewAss}>
            <div className="mb-3">
              <p className="m-0 fw-bold">Assign to</p>
              <div>
                <select
                  value={selectedOption}
                  onChange={handleChange}
                  className="mb-3 form-select"
                  aria-label="Default select example"
                >
                  <option value="">--Choose--</option>

                  {assignees?.map((assignee) => {
                    return (
                      <option key={assignee.username} value={assignee.email}>
                        {assignee.username}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="text-end">
                {showFormAignee ? (
                  <p className="text-success text-end py-2">
                    Sent successfully
                  </p>
                ) : (
                  <button
                    type="submit"
                    className="border-0 p-1 px-3 rounded-pill text-white"
                    style={{ backgroundColor: `var(--main-color2)` }}
                  >
                    Assign
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="mb-3">
            <p className="m-0 fw-bold">Classification</p>
            <div className="border p-2 Tajawal">
              {caseDetails?.classification}
            </div>
          </div>
          <div className="mb-3">
            <p className="m-0 fw-bold">Company</p>
            <div className="border p-2">{caseDetails?.company}</div>
          </div>
          <div className="mb-3">
            <p className="m-0 fw-bold">Received on</p>
            <div className="border p-2">{caseDetails?.creation_date}</div>
          </div>
          <div className="mb-3">
            <p className="m-0 fw-bold">Assignee</p>
            <div className="border p-2 text-wrap ">{caseDetails?.asignee}</div>
          </div>
          {/* <div className="mb-3">
            <p className="m-0 fw-bold">Expected Response Date</p>
            <div className="border p-2">10 February 2024 2:20</div>
          </div> */}
          <div className="mb-3">
            <p className="m-0 fw-bold">Last Updated</p>
            <div className="border p-2">{caseDetails.last_update}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Case;
